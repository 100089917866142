import { Includes, Metadata, Sys, Sys1 } from "@components/NFTItem";
import LoaderComponent from "@components/Paceloader/Loader";
import { Configuration } from "@constants";
import {
  useCollectionBySlugQuery,
  useGetProjectsQuery,
} from "@graphql-generated";
import NewLanding from "@layouts/newlanding";
import { ContentfulService } from "@services/contentfulService";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "src/utils/commonCheck";

interface HomePageProps { }
export interface CFSHomeItem {
  sys?: Sys;
  total?: number;
  skip?: number;
  limit?: number;
  items?: ItemsHomeEntity[] | null;
  includes?: Includes;
}

interface ItemsHomeEntity {
  metadata: Metadata;
  sys: Sys1;
  fields: FieldsHome;
}

interface FieldsHome {
  about: String;
  description: String;
  subTitle: String;
  title: String;
  projectList: any;

  instagram: string;
  facebook: string;
  youtube: string;
  discord: string;
  wechat: string;
  weibo: string;
  artsy: string;
  twitter: string;
  saleType: string;
  bannerVideoUrl: string;
}

const Home: NextPage<HomePageProps> = ({ }: HomePageProps) => {
  const router = useRouter();
  const [contentData, setContentdata] = useState({});
  const { data, error, loading, refetch } = useCollectionBySlugQuery({
    variables: {
      slug: Configuration.COLLECTION_SLUG,
      marketplaceID: Configuration.MARKETPLACE_ID,
    },
  });

  refetch();
  const {
    data: projectData,
    loading: projectLoadingoading,
    refetch: projectRefetch,
  } = useGetProjectsQuery({
    variables: { marketplaceId: Configuration.MARKETPLACE_ID },
  });
  projectRefetch();
  const [loader, setLoader] = useState(false);

  const cfs = useMemo(() => {
    return new ContentfulService();
  }, []);
  const [cfsItem, setCfsItem] = useState<CFSHomeItem>({});
  useEffect(() => {
    getHomePageCFS();
  }, []);

  useEffect(() => {
    const auth0Redirection = localStorage.getItem("auth0Redirection");
    if (!isEmpty(auth0Redirection) && auth0Redirection) {
      router.push(auth0Redirection);
      localStorage.removeItem("auth0Redirection");
    }
  }, []);

  if (JSON.stringify(data) !== JSON.stringify(contentData)) {
    if (data) {
      setContentdata(data);
    }
  }
  const getHomePageCFS = () => {
    setLoader(true);
    cfs
      .getHomePageItem()
      .then((res) => {
        setCfsItem(res as unknown as CFSHomeItem);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("errrr", err);
      });
  };
  return (
    <>
      {loader || loading || projectLoadingoading ? (
        <LoaderComponent />
      ) : (
        // <HomeLayout data={contentData} homeCfs={cfsItem} />
        <NewLanding homeCfs={cfsItem} projectMojito={projectData} />
      )}
    </>
  );
};

// This function gets called at build time on server-side.
// It won't be called on client-side, so you can even do
// direct database queries.
export async function getStaticProps() {
  // Call an external API endpoint to get posts.
  return {
    props: {},
  };
}

export default Home;
